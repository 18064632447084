<template>
	<div class="404">
		<h1 class="indigo--text text--darken-4 text-center">Erro 404 - Página não encontrada</h1>
	</div>
</template>
<script>
export default {
	data() {
		return {
			nome: localStorage.getItem('nome_usuario')
		}
	},
	mounted() {
		// this.$http.get('nodeapi.php').then((r) => {
		// 	console.log(r.body);
		// })
	}
};
</script>
